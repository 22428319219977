<template>
    <div>
        <GenericCrud
            childCrud
            routeName="AdminBlacklist"
            routeAction="list"
            routeChildAction="item"
            :mainListFilterLabel="$t('admin.blacklist.country.filterLabel')"
            mainListScrollToSelected
            :navbarTitle="$t('admin.blacklist.buttonLabel')"
            :navbarBackRouteWithoutHistory="{ name: 'Admin' }"
            :storeData="{
                mainListItems: {
                    action: 'countries/fetch',
                    filterFetchedItems: 'eu',
                    payload: { force: true },
                    options: null,
                    itemText: 'countryName',
                },
                mainItemById: {
                    action: 'blacklist/fetchByCountryId',
                    payload: null,
                    options: null,
                    listProp: null,
                    itemText: 'description',
                    filterLabel: $t('admin.blacklist.filterLabel'),
                    scrollToSelected: true,
                },
                childItemById: {
                    action: 'blacklist/fetchById',
                    payload: null,
                    options: null,
                },
                edit: {
                    action: 'blacklist/edit',
                    payload: null,
                    options: null,
                },
                add: {
                    action: 'blacklist/add',
                    payload: null,
                    options: null,
                },
                delete: {
                    action: 'blacklist/delete',
                    payload: null,
                    options: null,
                },
            }"
            :newItemModelFields="{
                description: null,
                whiteList: null,
                forcedTranslation: true,
                country: country ? country.countryCode : null,
                countryStates: []
            }"
        >
            <template v-slot:editorMask="x">
                <EditorMask
                    v-model="x.selectedChildItem"
                    :parentId="x.parentId"
                    :key="x.id"
                />
            </template>
        </GenericCrud>
        <BaseResponseDialog
            v-model="baseResponseDialog"
            errorDialog
            :headline="baseResponseDialogText.headline"
            :message="baseResponseDialogText.message"
            :items="baseResponseDialogText.items"
            maxWidth="500"
        />
    </div>
</template>

<script>
import GenericCrud from '@/components/generic/GenericCrud.vue';
import EditorMask from './EditorMask.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'AdminBlacklistIndex',
    components: {
        GenericCrud,
        EditorMask,
    },
    i18n: {
        messages: {
            en: require('@/locales/Admin/en.json'),
            de: require('@/locales/Admin/de.json'),
        },
    },
    data() {
        return {
            baseResponseDialog: false,
            baseResponseDialogText: {
                headline: null,
                message: null,
                items: [],
            },
        };
    },
    computed: {
        ...mapGetters({
            countryById: 'countries/getCountryById',
        }),
        country () {
            return this.countryById(this.$route.params.id);
        }
    },
    methods: {
        ...mapActions({
            fetchCountryStates: 'countries/fetchCountryStates',
        })
    },
    mounted () {
        this.$wait.start('fetch country states');
        this.fetchCountryStates().then(() => {
            this.$wait.end('fetch country states');
        });
    }
};
</script>
