<template>
    <v-container fluid class="ma-0 mb-3 pa-0 pl-2 pt-2">
        <v-row class="justify-space-arround">
            <v-col cols="12" md="6">
                <v-card class="default" outlined height="100%">
                    <v-toolbar short flat color="secondary">
                        <v-toolbar-title
                            class="text-subtitle-1 primary--text"
                            v-text="$t('admin.blacklist.headline')"
                        />
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-select 
                            v-if="enableCountryStates"
                            :loading="$wait.is('fetch country states')"
                            v-model="model.countryStates"
                            :items="countryStates"
                            item-value="id"
                            :item-text="getCountryStateName"
                            return-object
                            :label="$t('admin.blacklist.stateField.fieldLabel')"
                            :hint="$t('admin.blacklist.stateField.fieldHint')"
                            persistent-hint
                            filled
                            background-color="transparent"
                            clearable
                            multiple
                        ></v-select>
                        <LeaTipTapEditor
                            v-model="model.description"
                            :label="$t('admin.blacklist.descriptionField.fieldLabel')"
                            :error-messages="errors.collect('description')"
                            data-vv-name="description"
                            v-validate="'required'"
                            required
                            spellcheck="true"
                        />
                        <v-switch
                            v-model="model.whiteList"
                            color="primary"
                            :label="$t('admin.blacklist.whiteListField.fieldLabel')"
                            class="mt-0"
                        ></v-switch>
                        <v-switch
                            v-if="enableTranslation"
                            v-model="model.forcedTranslation"
                            color="primary"
                            :label="$t('admin.forcedTranslation.label')"
                            :hint="$t('admin.forcedTranslation.hint')"
                            persistent-hint
                            class="mt-0"
                        ></v-switch>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { isUUID } from '@/components/vvt/ModelMixin.js';
import LeaTipTapEditor from '@/components/Input/LeaTipTapEditor.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'AdminBlacklistEditorMask',
    inject: {
        $validator: '$validator',
    },
    components: {
        LeaTipTapEditor
    },
    props: {
        value: Object
    },
    i18n: {
        messages: {
            en: require('@/locales/Admin/en.json'),
            de: require('@/locales/Admin/de.json'),
        },
    },
    data() {
        return {
            model: this.value
        };
    },
    methods: {
        getCountryStateName (item) {
            var { state} = item;
            return this.$t('countryStates.de.' + state.toLowerCase());
        }
    },
    computed: {
        ...mapGetters({
            countryStates: 'countries/getCountryStates'
        }),
        enableTranslation() {
            return !isUUID(this.model.id);
        },
        enableCountryStates() {
            return this.model.country === 'DE';
        }
    },
};
</script>
