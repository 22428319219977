var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GenericCrud',{attrs:{"childCrud":"","routeName":"AdminBlacklist","routeAction":"list","routeChildAction":"item","mainListFilterLabel":_vm.$t('admin.blacklist.country.filterLabel'),"mainListScrollToSelected":"","navbarTitle":_vm.$t('admin.blacklist.buttonLabel'),"navbarBackRouteWithoutHistory":{ name: 'Admin' },"storeData":{
            mainListItems: {
                action: 'countries/fetch',
                filterFetchedItems: 'eu',
                payload: { force: true },
                options: null,
                itemText: 'countryName',
            },
            mainItemById: {
                action: 'blacklist/fetchByCountryId',
                payload: null,
                options: null,
                listProp: null,
                itemText: 'description',
                filterLabel: _vm.$t('admin.blacklist.filterLabel'),
                scrollToSelected: true,
            },
            childItemById: {
                action: 'blacklist/fetchById',
                payload: null,
                options: null,
            },
            edit: {
                action: 'blacklist/edit',
                payload: null,
                options: null,
            },
            add: {
                action: 'blacklist/add',
                payload: null,
                options: null,
            },
            delete: {
                action: 'blacklist/delete',
                payload: null,
                options: null,
            },
        },"newItemModelFields":{
            description: null,
            whiteList: null,
            forcedTranslation: true,
            country: _vm.country ? _vm.country.countryCode : null,
            countryStates: []
        }},scopedSlots:_vm._u([{key:"editorMask",fn:function(x){return [_c('EditorMask',{key:x.id,attrs:{"parentId":x.parentId},model:{value:(x.selectedChildItem),callback:function ($$v) {_vm.$set(x, "selectedChildItem", $$v)},expression:"x.selectedChildItem"}})]}}])}),_c('BaseResponseDialog',{attrs:{"errorDialog":"","headline":_vm.baseResponseDialogText.headline,"message":_vm.baseResponseDialogText.message,"items":_vm.baseResponseDialogText.items,"maxWidth":"500"},model:{value:(_vm.baseResponseDialog),callback:function ($$v) {_vm.baseResponseDialog=$$v},expression:"baseResponseDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }